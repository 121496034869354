import { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, ListSubheader, Stack, Typography } from '@mui/material';
import Image from '../../Image';
// hooks
import useLocales from '../../../hooks/useLocales';

import { useDispatch, useSelector } from '../../../redux/store';
import { setActiveTab } from '../../../redux/slices/game';
import { openLoginSignup } from '../../../redux/slices/authPages';

import useResponsive from '../../../hooks/useResponsive';
import Iconify from '../../Iconify';
import palette from '../../../theme/palette';
import LanguagePopover from '../../../layouts/dashboard/header/LanguagePopover';
import { openTransactionsDialog } from '../../../redux/slices/transactionsDialog';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
  onCloseSidebar: PropTypes.func,
};

const ButtonContainer = styled(Stack)(({ theme }) => ({
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 100%',
  height: '43px',
  alignItems: 'center',
  cursor: 'pointer',
}));

const StyledImage = styled(Image)(({ theme }) => ({
  width: 38,
  height: 'auto',
  paddingLeft: 8,
}));

const NavWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '10px',
  cursor: 'pointer',
  padding: '5px 8px',
  position: 'relative',
  height: '100%',
}));

const NavImage = styled(Box)(({ theme }) => ({
  width: '25px',
  height: '25px',
  display: 'flex',
  alignItems: 'center',
}));

const NavBtnText = styled(Typography)(() => ({
  fontSize: '13px',
  fontWeight: 900,
  fontFamily: 'sans-serif',
  lineHeight: '1.5',
  textAlign: 'center',
  textDecoration: 'none',
  color: palette.navBarVertical.menu.color,
  marginLeft: '16px',
}));

export default function NavSectionVertical({ navConfig, isCollapse = false, onCloseSidebar = () => {}, ...other }) {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const theme = useTheme();

  const { translate } = useLocales();

  const { gameCategsAndCompanies, activeTab, angPauPromos } = useSelector((x) => ({ ...x.game, ...x.lookup }));

  const { siteSettings } = useSelector((x) => x.lookup);

  const isDesktop = useResponsive('up', 'lg');

  const isAuthenticated = localStorage.getItem('accessToken');

  const openSocial = (link) => {
    window.open(
      link,
      '_blank'
      // 'noopener noreferrer'
    );
  };

  const makeGameCategory = (_category) => {
    let nav = '';

    if (_category?.slug.toLowerCase().indexOf('slots') !== -1)
      nav = { src: require('../../../assets/menu/side-nav-slot.png') };

    if (_category?.slug.toLowerCase().indexOf('live-casino') !== -1)
      nav = { src: require('../../../assets/menu/side-nav-card.png') };

    if (_category?.slug.toLowerCase().indexOf('fishing') !== -1)
      nav = { src: require('../../../assets/menu/side-nav-fish.png') };

    if (_category?.slug.toLowerCase().indexOf('card') !== -1)
      nav = { src: require('../../../assets/menu/side-nav-card.png') };

    if (_category?.slug.toLowerCase().indexOf('sports') !== -1) nav = { icon: 'ri:football-fill' };

    if (_category?.slug.toLowerCase().indexOf('e-sports') !== -1)
      nav = { src: require('../../../assets/menu/nav-e-sports.png') };

    if (_category?.slug.toLowerCase().indexOf('instant-game') !== -1) nav = { icon: 'ph:lightning-fill' };

    return (
      <NavWrapper
        key={_category?.key}
        onClick={() => {
          if (!isDesktop) onCloseSidebar();
          navigate(`/games/${_category?.slug}`);
        }}
        sx={activeTab === _category?.slug ? { backgroundColor: 'background.paper' } : {}}
      >
        {nav?.src ? (
          <NavImage>
            <Image
              src={nav.src}
              sx={{
                mx: 'auto',
                cursor: 'pointer',
              }}
            />
          </NavImage>
        ) : (
          <></>
        )}
        {nav?.icon ? (
          <Iconify icon={nav.icon} sx={{ width: '25px', height: '25px !important', maxHeight: '25px !important' }} />
        ) : (
          <></>
        )}
        <NavBtnText>{translate(_category?.name)}</NavBtnText>
      </NavWrapper>
    );
  };

  return (
    <Box {...other}>
      <Stack sx={{ px: 1, pb: 1 }} spacing={0.5}>
        {angPauPromos?.data?.length ? (
          <Box onClick={() => navigate('angpau')}>
            <Image src={require('../../../assets/menu/nav-earn.png')} />
          </Box>
        ) : (
          ''
        )}

        <NavWrapper onClick={() => navigate('/home')}>
          <NavImage>
            <Image
              src={require('../../../assets/menu/side-nav-home.png')}
              sx={{
                mx: 'auto',
                cursor: 'pointer',
              }}
            />
          </NavImage>
          <NavBtnText>{translate('home')}</NavBtnText>
        </NavWrapper>

        {gameCategsAndCompanies.length > 0 &&
          gameCategsAndCompanies.map((item, index) => <Box key={index}>{makeGameCategory(item)}</Box>)}

        <NavWrapper onClick={() => navigate('/promotion')}>
          <NavImage>
            <Image
              src={require('../../../assets/menu/nav-promo.png')}
              sx={{
                mx: 'auto',
                cursor: 'pointer',
              }}
            />
          </NavImage>
          <NavBtnText>{translate('promotions')}</NavBtnText>
        </NavWrapper>

        <NavWrapper
          onClick={() => {
            if (!isAuthenticated) dispatch(openLoginSignup({ open: true, isLogin: true }));
            else navigate('user/wallet');
            onCloseSidebar();
          }}
        >
          <NavImage>
            <Image
              src={require('../../../assets/menu/nav-deposit.png')}
              sx={{
                mx: 'auto',
                cursor: 'pointer',
              }}
            />
          </NavImage>
          <NavBtnText>{translate('deposit')}</NavBtnText>
        </NavWrapper>

        <NavWrapper
          onClick={() => {
            if (!isAuthenticated) dispatch(openLoginSignup({ open: true, isLogin: true }));
            else navigate('/referral');
            onCloseSidebar();
          }}
        >
          <NavImage>
            <Image
              src={require('../../../assets/menu/nav-referral.png')}
              sx={{
                mx: 'auto',
                cursor: 'pointer',
              }}
            />
          </NavImage>
          <NavBtnText>{translate('referral')}</NavBtnText>
        </NavWrapper>
        {/* <Image src={require('../../../assets/menu/refer.png')} sx={{ width: '100%', height: 'auto' }} /> */}
        {isAuthenticated ? (
          <>
            <NavBottomBtn
              onClick={() => {
                navigate('/history/bet');
                if (!isDesktop) onCloseSidebar();
              }}
              title={translate('game_history')}
              icon={'solar:history-outline'}
            />
            <NavBottomBtn
              onClick={() => {
                navigate('/history/transaction');
                if (!isDesktop) onCloseSidebar();
              }}
              title={translate('transaction_history')}
              icon={'la:exchange-alt'}
            />
          </>
        ) : (
          <></>
        )}
        {/* <NavBottomBtn
            title={translate('Contact Us')}
            icon={'fluent:person-support-24-regular'}
          />
          <NavBottomBtn title={translate('FAQ')} icon={'octicon:question-24'} />
          <NavBottomBtn title={translate('Download')} icon="solar:download-linear" /> */}
        {/* <LanguagePopover
            CustomBtn={<NavBottomBtn icon="octicon:globe-24" />}
            PopoverProps={{
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              disabledArrow: true,
            }}
          /> */}
      </Stack>
    </Box>
  );
}

const NavBottomBtn = ({ icon, image, title, onClick = () => {} }) => (
  <NavWrapper onClick={onClick}>
    {icon ? <Iconify icon={icon} sx={{ width: '25px', height: '25px !important' }} /> : <></>}
    {image ? (
      <NavImage>
        <Image
          src={image}
          sx={{
            mx: 'auto',
            cursor: 'pointer',
            '& img': {
              maxHeight: '25px',
            },
          }}
        />
      </NavImage>
    ) : (
      <></>
    )}
    <NavBtnText>{title}</NavBtnText>
  </NavWrapper>
);
