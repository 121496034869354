import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, InputBase, Modal, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import Iconify from '../../../../components/Iconify';
import NewButton from '../../../../components/NewButton';
import conObj from '../../../../utils/connection-assistant';
import useLocales from '../../../../hooks/useLocales';

const snackbarOptionsSuccess = { variant: 'success', autoHideDuration: 5000 };
const snackbarOptionsError = { variant: 'error', autoHideDuration: 5000 };

const ForgotPasswordDialog = ({ openForgotPasswordDialog, setOpenForgotPasswordDialog }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { translate } = useLocales();

  const [email, setEmail] = useState('');

  const onSubmit = () => {
    if (!email.match('[a-z0-9]+@[a-z]+.[a-z]{2,3}')) {
      enqueueSnackbar(translate('enter_valid_email'), snackbarOptionsError);
      return;
    }

    conObj
      .post(`forgot.json`, { 'user-email': email.trim() })
      .then((res) => {
        if (res.status === 200) {
          const { msg, status } = res.data?.data;
          if (status === 1) {
            enqueueSnackbar(translate('email_sent'), snackbarOptionsSuccess);
          }
        }
      })
      .catch((err) => {
        const snackbarOptions = { variant: 'error', autoHideDuration: 5000 };
        enqueueSnackbar(err.message, snackbarOptions);
        console.log(`Server Error: ${err.message}`);
      })
      .then(() => {
        setOpenForgotPasswordDialog(false);
      });
  };

  return (
    <Modal
      open={openForgotPasswordDialog}
      onClose={() => setOpenForgotPasswordDialog(false)}
      sx={{ overflow: 'hidden' }}
    >
      <Box
        sx={{
          backgroundColor: '#161f2c',
          borderRadius: '20px',
          height: 'auto',
          width: '96vw',
          maxWidth: '500px',
          overflow: 'auto',
          p: '50px 24px 24px',
          m: 'auto',
          boxShadow: '0 1px 2px -2px rgba(0,0,0,.64),0 3px 6px 0 rgba(0,0,0,.48),0 5px 12px 4px rgba(0,0,0,.36)',
          position: 'relative',
          marginTop: '50px',
        }}
        className="modal-container container-no-scrollbar"
      >
        <Iconify
          icon={'ep:close'}
          width={24}
          height={24}
          sx={{ cursor: 'pointer', position: 'absolute', right: 16, top: 16, color: 'rgb(147, 172, 211)' }}
          onClick={() => setOpenForgotPasswordDialog(false)}
        />

        <Typography sx={{ fontWeight: 600, color: 'white', fontSize: '18px', mb: 2 }}>Reset Password</Typography>
        <Box>
          <Box sx={{ mb: 2 }}>
            <Stack
              direction="row"
              alignItems="center"
              sx={{ borderRadius: '10px', border: '1px solid #202a39', height: '44px', p: '8.5px 13px' }}
            >
              <Iconify icon={'mdi-light:email'} width={24} height={24} sx={{ color: '#55657e' }} />
              <InputBase
                name="email"
                value={email}
                placeholder={translate('email')}
                //   InputLabelProps={{
                //     style: { color: '#999999' },
                //  }}
                sx={{ ml: 1, fontSize: '14px', fontWeight: 600, fontFamily: 'sans-serif', flexGrow: 1, color: 'white' }}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Stack>
          </Box>

          <NewButton
            fullWidth
            onClick={() => onSubmit()}
            label={translate('confirm')}
          />
        </Box>
      </Box>
    </Modal>
  );
};

ForgotPasswordDialog.propTypes = {
  openForgotPasswordDialog: PropTypes.bool,
  setOpenForgotPasswordDialog: PropTypes.func,
};

export default ForgotPasswordDialog;
