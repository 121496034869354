import PropTypes from 'prop-types';
import { Box, Drawer as CustomDrawer, Grid, Typography, styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useLocales from '../../../../hooks/useLocales';
import { useSelector } from '../../../../redux/store';

const ConfirmCon = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  width: '100%',
  background: 'linear-gradient(180deg,#674b00 10%,#111923 40%)',
  borderTopLeftRadius: '20px',
  borderTopRightRadius: '20px',
  boxShadow: '18px 22px 100px rgba(0,0,0,.55)',
  padding: 20,
  textAlign: 'center',
}));

const StyledText = styled(Typography)(({ theme }) => ({
  color: 'white',
  fontWeight: 800,
}));

const GridCon = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ConfirmButton = styled(LoadingButton)(({ theme }) => ({
  width: '70%',
  border: 'none',
  outline: 'none',
  position: 'relative',
  zIndex: 1,
  borderRadius: '20px',
  boxShadow: 'none',
  fontSize: '0.8rem',
  cursor: 'pointer',
  background: 'linear-gradient(90deg, #FFFAA8, #FEF7AA, #F0C91A, #FFFAB3, #FEF8AD, #F1CA1A, #FFFAB3, #FFFAA8)',
  '&::before': {
    content: '""',
    position: 'absolute',
    left: '1px',
    right: '1px',
    top: '1px',
    bottom: '1px',
    borderRadius: '20px',
    color: '#fff',
    background: '#000',
    zIndex: -1,
    transition: '200ms',
  },
  '&::after': {
    content: 'attr(data)',
    fontSize: '0.8rem',
    background: 'linear-gradient(90deg, #FFFAA8, #FEF7AA, #F0C91A, #FFFAB3, #FEF8AD, #F1CA1A, #FFFAB3, #FFFAA8)',
    backgroundClip: 'text',
    color: '#fff',
    transition: '200ms',
  },
}));

const CancelButton = styled(LoadingButton)(({ theme }) => ({
  width: '70%',
  border: 'none',
  outline: 'none',
  position: 'relative',
  zIndex: 1,
  borderRadius: '20px',
  boxShadow: 'none',
  fontSize: '0.8rem',
  cursor: 'pointer',
  background: 'linear-gradient(90deg, #BC0101 0%, #C81C1C 17.62%, #FA9A9A 26.88%, #C81C1C 32.84%, #C81C1C 38.5%, #FA9A9A 43.4%, #FA9A9A 52.96%, #C81C1C 59.75%, #C81C1C 63.11%, #FA9A9A 72.02%, #C81C1C 79.16%, #BC0101 100%)',
  '&::before': {
    content: '""',
    position: 'absolute',
    left: '1px',
    right: '1px',
    top: '1px',
    bottom: '1px',
    borderRadius: '20px',
    color: '#fff',
    background: '#000',
    zIndex: -1,
    transition: '200ms',
  },
  '&::after': {
    content: 'attr(data)',
    fontSize: '0.8rem',
    background: 'linear-gradient(90deg, #BC0101 0%, #C81C1C 17.62%, #FA9A9A 26.88%, #C81C1C 32.84%, #C81C1C 38.5%, #FA9A9A 43.4%, #FA9A9A 52.96%, #C81C1C 59.75%, #C81C1C 63.11%, #FA9A9A 72.02%, #C81C1C 79.16%, #BC0101 100%)',
    backgroundClip: 'text',
    color: '#fff',
    transition: '200ms',
  },
}));

const ConfirmWithdrawDrawer = ({ confirmWithdraw, toggleDrawer, withdrawAmount, submitWithdraw, isSubmitting }) => {
  const { translate } = useLocales();

  const { memberBalance } = useSelector((x) => x.lookup);

  return (
    <CustomDrawer
      anchor="bottom"
      open={confirmWithdraw}
      onClose={() => toggleDrawer(false)}
      sx={{ zIndex: 2000, borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
    >
      <ConfirmCon>
        <StyledText sx={{ fontSize: '1.2rem' }}>{translate('withdraw_amount')}</StyledText>
        <StyledText sx={{ fontSize: '1.6rem' }}>{`${process.env.REACT_APP_CURRENCY_CODE} ${withdrawAmount}`}</StyledText>

        <Grid container sx={{ mt: { xl: 0, lg: 0, md: 0, sm: 0, xs: 2 } }}>
          <GridCon item xl={6} lg={6} md={6} sm={6} xs={6}>
            <CancelButton 
              onClick={() => toggleDrawer(false)}
              sx={{
                color: 'white',
                fontSize: { xl: '1.2rem', lg: '1.2rem', md: '1.2rem', sm: '1.2rem', xs: '.75rem' },
                fontWeight: 800,
              }}
            >Cancel</CancelButton>
          </GridCon>
          <GridCon item xl={6} lg={6} md={6} sm={6} xs={6}>
            <ConfirmButton
              onClick={() => submitWithdraw()}
              variant={'contained'}
              color="info"
              disabled={isSubmitting}
              loading={isSubmitting}
              sx={{
                color: 'white',
                fontSize: { xl: '1.2rem', lg: '1.2rem', md: '1.2rem', sm: '1.2rem', xs: '.75rem' },
                fontWeight: 800,
              }}
            >
              {translate('confirm_to_pay')}
            </ConfirmButton>
          </GridCon>
        </Grid>
      </ConfirmCon>
    </CustomDrawer>
  );
};

ConfirmWithdrawDrawer.propTypes = {
  confirmWithdraw: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  withdrawAmount: PropTypes.string,
};

export default ConfirmWithdrawDrawer;
